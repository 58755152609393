import { SolidBoxedCategoryMenu } from '#components/ui/solid-boxed-category';
import type { Collection } from '#framework/__generated__';

interface StaticSidebarVerticalRectangleCategoriesProps {
  notFound: boolean;
  loading: boolean;
  categories: Collection[];
}

export const StaticSidebarVerticalRectangleCategories = ({
  notFound,
  categories,
  loading,
}: StaticSidebarVerticalRectangleCategoriesProps) => {
  /*if (loading) {
    return (
      <div className='hidden xl:block'>
        <div className='w-full h-52 flex justify-center mt-8 px-2'>
          <BakeryCategoryLoader />
        </div>
      </div>
    );
  }*/

  return (
    <div className='w-full bg-gray-100'>
      {!notFound ? (
        <div className='pt-5 px-4 lg:p-8 lg:pb-0'>
          <SolidBoxedCategoryMenu items={categories} className='py-8' />
        </div>
      ) : (
        <div className='min-h-full pt-6 pb-8 px-9 lg:p-8'>
          {/*<NotFound text='text-no-category' className='h-96' />*/}
        </div>
      )}
    </div>
  );
};

export default StaticSidebarVerticalRectangleCategories;
