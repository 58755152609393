import cn from 'classnames';
import type { FC } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { productPlaceholder } from '#assets/svg/product-placeholder';
import { ArrowNextIcon } from '#components/icons/arrow-next-icon';
import { ArrowPrevIcon } from '#components/icons/arrow-prev-icon';
import type { Collection } from '#framework/__generated__';
import { useSearchParams } from '#lib/use-search-params';
import { useUnescaped } from '#lib/use-unescaped';

import { Image } from './image';
import { Navigation, Swiper, SwiperSlide } from './swiper';

const CategoryItem: FC<{ item: Collection }> = ({ item }) => {
  const [{ category }, { urlWith, urlWithout }] = useSearchParams();
  const categoryName = useUnescaped(item.name);

  const onCategoryClick = (slug: string) => {
    if (category !== slug) {
      gtag('event', 'select_content', {
        content_type: 'category',
        content_id: slug,
      });
    }

    gtag('event', 'page_view', {
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_title: document.title,
    });
  };

  return (
    <a
      href={
        category === item.slug
          ? urlWithout(['category'])
          : urlWith({ category: item.slug })
      }
      className={cn(
        'block text-center rounded bg-light relative overflow-hidden cursor-pointer border-2',
        category === item.slug ? 'border-accent' : 'border-light'
      )}
      role='button'
      onClick={() => onCategoryClick(item?.slug)}
    >
      <div className='flex items-center justify-center h-32 w-auto relative overflow-hidden mb-3 mt-0'>
        <Image
          asset={item.featuredAsset}
          src={productPlaceholder}
          alt={categoryName!}
          layout='fill'
          objectFit='cover'
        />
      </div>
      <span className='text-sm font-semibold text-heading text-center px-4 pb-4 block'>
        {categoryName}
      </span>
    </a>
  );
};

export const SolidBoxedCategoryMenu: FC<{
  items: Collection[];
  className: string;
}> = ({ items }) => {
  const { t } = useTranslation('common');

  const prevRef = useRef<HTMLDivElement>(null);
  const nextRef = useRef<HTMLDivElement>(null);

  const breakpoints = {
    0: {
      slidesPerView: 1,
    },

    320: {
      slidesPerView: 2,
    },

    440: {
      slidesPerView: 3,
    },

    620: {
      slidesPerView: 4,
    },

    820: {
      slidesPerView: 5,
    },

    1100: {
      slidesPerView: 6,
    },

    1280: {
      slidesPerView: 7,
    },
  };

  return (
    <div className='relative'>
      <Swiper
        id='category-card-menu'
        modules={[Navigation]}
        navigation={{
          prevEl: prevRef.current!, // Assert non-null
          nextEl: nextRef.current!, // Assert non-null
        }}
        breakpoints={breakpoints}
        slidesPerView={7}
        spaceBetween={10}
      >
        {items?.map((category: Collection, idx: number) => (
          <SwiperSlide key={idx}>
            <CategoryItem item={category} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div
        ref={prevRef}
        className='banner-slider-prev w-8 h-8 flex items-center justify-center text-heading bg-light shadow-300 outline-none rounded-full absolute top-1/2 -mt-4 z-10 cursor-pointer -left-3 lg:-left-4 focus:outline-none'
      >
        <span className='sr-only'>{t('text.previous')}</span>
        <ArrowPrevIcon />
      </div>

      <div
        ref={nextRef}
        className='banner-slider-next w-8 h-8 flex items-center justify-center text-heading bg-light shadow-300 outline-none rounded-full absolute top-1/2 -mt-4 z-10 cursor-pointer -right-3 lg:-right-4 focus:outline-none'
      >
        <span className='sr-only'>{t('text.next')}</span>
        <ArrowNextIcon />
      </div>
    </div>
  );
};
